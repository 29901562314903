import './Containers.css';

function Contact() {
  const copyEmailToClipboard = () => {
    const email = 'simon_hsieh@berkeley.edu';
    navigator.clipboard.writeText(email).then(() => {
      alert('Email address copied to clipboard!');
    }).catch(err => {
      console.error('Failed to copy email address: ', err);
    });
  };

  return (
    <div className='page-wrap'>
      <div className='flex-box glow-container bright-yellow-glow'>
        <div>
          <div>
            <h1>Get in Contact!</h1>
          </div>
          <a href='https://www.linkedin.com/in/simonhsieh123' target='_blank' rel='noreferrer'>
            <img src={'linkedin.svg'} alt='LinkedIn' className='contact-icon' />
          </a>
          <a href='https://wa.me/15109907408' target='_blank' rel='noreferrer'>
            <img src={'whatsapp.svg'} alt='WhatsApp' className='contact-icon' />
          </a>
          <a href='https://www.instagram.com/itssimonhsieh' target='_blank' rel='noreferrer'>
            <img src={'instagram.svg'} alt='Instagram' className='contact-icon' />
          </a>
          <a href='https://discord.com/invite/hyKV2yMV' target='_blank' rel='noreferrer'>
            <img src={'discord.svg'} alt='WeChat' className='contact-icon' />
          </a>
          <a href='mailto:simon_hsieh@berkeley.edu' onClick={copyEmailToClipboard}>
            <img src={'email.svg'} alt='Email' className='contact-icon' />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Contact;