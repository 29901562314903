function Resume() {
  return (
    <iframe
    src='/resume.pdf'
    style={{
        flex: 1,
        border: 'none'
    }}
    title='Resume'
    />
  );
}

export default Resume;