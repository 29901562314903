import { Link } from 'react-router-dom';
import './Containers.css';

function Navbar() {
  return (
    <nav className='navbar'>
      <h1 className='navbar-title'>Simon's Zone</h1>
      <div className='navbar-links'>
        <Link to='/about'>About me</Link>
        <Link to='/resume'>Resume</Link>
        <Link to='/contact'>Contact</Link>
        <Link to='/cat'>Boop!</Link>
      </div>
    </nav>
  );
}

export default Navbar;