import './Containers.css'
import './Animations.css'

function About() {
  return (
    <div className='page-wrap'>
      <div className='flex-box'>
        <img src='portrait.png' alt='Profile pic' className='img-small jiggle'/>
        <div className='flex-box glow-container vibrant-orange-glow'>
          <div className='text-wrap'>
            <h1>Hey, Simon here!</h1>
            <p>I'm a software engineer at Berkeley. This website gives a quick snapshot of my projects and skills.</p>
          </div>
        </div>
      </div>

      <div className='flex-box'>
        <div className='flex-box glow-container electric-blue-glow'>
          <div className='text-wrap'>
          <h2>Education</h2>
          <p>B.A. in Data Science, UC Berkeley (2020 - 2024)</p>
          <p>Key courses</p>
          <ul>
            <li>Artificial intelligence</li>
            <li>Computer architecture</li>
            <li>Data structures</li>
            <li>Computer security</li>
          </ul>
          </div>
        </div>
        <img src='oski.png' alt='Oski' className='img-small up-down'/>
      </div>

      <div className='flex-box'>
        <img src='python.png' alt='Python' className='img-small left-right'/>
        <div className='flex-box glow-container lime-green-glow'>
          <div className='text-wrap'>
            <h2>Skills</h2>
            <p>Programming languages</p>
            <ul>
              <li>Python (4 years)</li>
              <li>Java (2 years)</li>
              <li>ReactJS (1 year)</li>
              <li>Flutter (1 year)</li>
            </ul>
            <p>Core competencies</p>
            <ul>
              <li>Full-stack development (1 year)</li>
              <li>Data analysis (1 year)</li>
            </ul>
          </div>
        </div>
      </div>

      <div className='flex-box'>
        <div className='flex-box glow-container neon-pink-glow'>
          <div className='text-wrap'>
            <h2>Projects</h2>
            <p>PawPals</p>
            <ul>
              <li>Social media app for pet owners</li>
              <li>AI algorithms and features</li>
              <li>Built with flutter and firebase</li>
            </ul>
            <p>MeepChat</p>
            <ul>
              <li>MERN stack chat app</li>
              <li>Lets users like and dislike messages</li>
            </ul>
            <p>CattoFilter</p>
            <ul>
              <li>Camera app prototype</li>
              <li>Adds funny filters on cats</li>
              <li>Powered by OpenCV and Python</li>
            </ul>
          </div>
        </div>
        <img src='chat.png' alt='Chat' className='img-small scale'/>
      </div>

      <div className='flex-box'>
      <img src='work.webp' alt='Work' className='img-small'/>
        <div className='flex-box glow-container bright-yellow-glow'>
          <div className='text-wrap'>
            <h2>Work Experience</h2>
            <p>Computer Vision Intern, huupe (Summer 2023)</p>
            <ul>
              <li>Developed an AI spatial heuristic</li>
              <li>Automated QA with 200+ unit tests</li>
            </ul>
            <p>Summer Intern, Ijuhin Human Resources Consulting (Summer 2021)</p>
            <ul>
              <li>Focused on hiring for backend developer roles</li>
              <li>Familiarized with BI and database tools</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
