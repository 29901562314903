import './Containers.css';
import './Animations.css'

function Cat() {
  return (
    <div className='page-wrap'>
      <div className='flex-box glow-container vibrant-orange-glow'>
        <h1 style={{margin: '0px'}}>Meet the Meepy Creatures!</h1>
      </div>

      <div className='flex-box'>
        <img src='mini.png' alt='Mini' className='jiggle'/>
        <div className='flex-box glow-container lime-green-glow'>
          <div className='text-wrap'>
            <h2>Mini</h2>
            <p>Aka BoisBois. He is always up to no good, whether it be destroying boxes or stealing food. Turns into devil incarnate when unfamiliar cats are around.</p>
            <p>Daily activities:</p>
            <ul>
              <li>Running on treadmill</li>
              <li>Attention seeking</li>
            </ul>
            <p>Favorite treats:</p>
            <ul>
              <li>Squeeze up</li>
              <li>Soupy food toppers</li>
            </ul>
          </div>
        </div>
      </div>

      <div className='flex-box'>
        <div className='flex-box glow-container electric-blue-glow'>
          <div className='text-wrap'>
            <h2>Binx</h2>
            <p>This one's the big guy. Very chill. Can always be spotted on top of soft surfaces (beanbags, mattresses, etc).</p>
            <p>Daily activities:</p>
            <ul>
              <li>Chasing Mini</li>
              <li>Eeping</li>
              <li>More eeping</li>
            </ul>
            <p>Favorite treats:</p>
            <ul>
              <li>Dental biscuits</li>
              <li>Crunchy dry food</li>
            </ul>
          </div>
        </div>
        <img src='binx.png' alt='Mini' className='jiggle'/>
      </div>
    </div>
  );
}

export default Cat;